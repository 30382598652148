// event-pool.js

class EventPool {
    constructor() {
      this.events = {}; // 存储事件及其对应的回调函数
    }
  
    // 注册事件
    on(event, callback) {
      if (!this.events[event]) {
        this.events[event] = [];
      }
      // this.events[event].push(callback);
      // 如果该回调已存在，则不重复添加
      if (this.events[event].length == 0) {
        this.events[event].push(callback);
      }
    }
  
    // 触发事件
    emit(event, ...args) {
      if (this.events[event]) {
        this.events[event].forEach(callback => callback(...args));
      }
    }
  
    // 解绑单个事件
    off(event, callback) {
      if (this.events[event]) {
        const index = this.events[event].indexOf(callback);
        if (index !== -1) {
          this.events[event].splice(index, 1);
        }
      }
    }
  
    // 解绑所有事件
    offAll(event) {
      if (this.events[event]) {
        delete this.events[event];
      }
    }
  
    // 清空所有事件
    clearAll() {
      this.events = {};
    }
  }
  
  const eventPool = new EventPool();
  // console.log('rewrewrwerweahhah')
  export default {
    install(Vue) {
      
      // 将 eventPool 插件添加到 Vue 原型中
      Vue.prototype.$eventPool = eventPool;
    },
  };
  