/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: /thmall-operation/src/service/request.js
 */
import apis from './apis'
import axiosInstance from './axios'

// 登陆
export async function login(payload) {
  try {
    const data = await axiosInstance.post(apis.login, { ...payload, userType: 2 })
    return data
  } catch (e) {
    console.log(e)
  }
}
// 登陆
export async function getOrder() {
  try {
    const data = await axiosInstance.get(apis.getOrder)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 店铺认证发送邮箱验证码
 * @return {*}
 */
export async function sendEmailCode(payload) {
  try {
    const data = await axiosInstance.get(apis.sendEmailCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 发送手机验证码
 * @return {*}
 */
export async function sendSms(payload) {
  try {
    const data = await axiosInstance.post(apis.sendSms, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 验证码验证
 * @return {*}
 */
export async function verifyCaptcha(payload) {
  try {
    const data = await axiosInstance.post(apis.verifyCaptcha, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增店铺认证
 * @return {*}
 */
export async function submit(payload) {
  try {
    const data = await axiosInstance.post(apis.submit, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 邮箱验证码校验
 * @return {*}
 * String emailCode (邮箱验证码)
 * String emailName( 邮箱信息地址)
 * Integer type( 1管理员信息/2店铺信息)
 */
export async function validateEmailCode(payload) {
  try {
    const data = await axiosInstance.get(apis.validateEmailCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 公司信息(校验商业登记证以及社会统一信用代码去重)
 * @return {*}
 * type 地区区分 1澳门 2大陆 3香港
 * businessRegistrationNum 商业登记证编号
 * unifiedCreditCode 统一社会信用代码
 */
export async function queryList(payload) {
  try {
    const data = await axiosInstance.get(apis.queryList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

// ============================== W W S == 商品模块 ======================

/**
 * @description: 商品列表
 * @return {*}
 */
export async function getProductList(payload) {
  try {
    const data = await axiosInstance.post(apis.getProductList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增参数/规格 名称
 * @return {*}
 */
export async function addParamLabel(payload) {
  try {
    const data = await axiosInstance.post(apis.addLabel, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改参数/规格 名称
 * @return {*}
 */
export async function updateParam(payload) {
  try {
    const data = await axiosInstance.post(apis.updateParam, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增参数/规格 值
 * @return {*}
 */
export async function addParamValue(payload) {
  try {
    const data = await axiosInstance.post(apis.addvalue, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增/编辑 商品
 * @return {*}
 */
export async function ProductAddEdit(payload) {
  try {
    const data = await axiosInstance.post(apis.addEditProduct, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 商品分组树
 * @return {*}
 */
export async function getGroupTree() {
  try {
    const data = await axiosInstance.get(apis.getGroupTree)
    data.data = removeEmptyListNode(data.data)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 商品类目树---用于商品
 * @return {*}
 */
export async function getCategoryTree() {
  try {
    const data = await axiosInstance.get(apis.getCategoryTree)
    data.data = removeEmptyListNode(data.data)
    return data
  } catch (e) {
    console.log(e)
  }
}
function removeEmptyListNode(data) {
  // 清理空节点
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].listNode && data[i].listNode.length === 0) {
        delete data[i].listNode
      } else {
        removeEmptyListNode(data[i].listNode)
      }
    }
  }
  return data
}

/**
 * @description: 商品类目树---用于管理类目
 * @return {*}
 */
export async function getAllCategoryTree() {
  try {
    const data = await axiosInstance.get(apis.getAllCategoryTree)
    data.data = removeEmptyListNode(data.data)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商品品牌
 * @return {*}
 */
export async function getProductBrands() {
  try {
    const data = await axiosInstance.get(apis.getProductBrands)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改品牌启用状态
 * @return {*}
 */
export async function updateDelete(payload) {
  try {
    const data = await axiosInstance.post(apis.updateDelete, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改属性启用状态
 * @return {*}
 */
export async function updateParamValueDelete(payload) {
  try {
    const data = await axiosInstance.post(apis.updateParamValueDelete, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 分页查询商品品牌
 * @return {*}
 */
export async function getPageBrands(payload) {
  try {
    const data = await axiosInstance.post(apis.getPageBrands, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 分页查询属性
 * @return {*}
 */
export async function getPageParams(payload) {
  try {
    const data = await axiosInstance.post(apis.getPageParams, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 分页查询属性
 * @return {*}
 */
export async function getParamDetail(payload) {
  try {
    const data = await axiosInstance.get(apis.getParamDetail, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询规格。参数 名称和值
 * @return {*}
 */
export async function getParamDict(payload) {
  try {
    const data = await axiosInstance.post(apis.getParamDict, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 回车新增品牌
 * @return {*}
 */
export async function addBrand(payload) {
  try {
    const data = await axiosInstance.post(apis.addBrand, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 获取商品详情
 * @return {*}
 */
export async function getProductDetail(payload) {
  try {
    const data = await axiosInstance.get(apis.getProductDetail, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 获取店铺运费模板
 * @return {*}
 */
export async function getFreightMethod(payload) {
  try {
    const data = await axiosInstance.get(apis.getFreightMethod, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询物流信息
 * @return {*}
 */
export async function findLogisticsManageInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.findLogisticsManageInfo, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询用户信息认证状态
 * @return {*}
 */
export async function queryStatusInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.queryStatusInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: （店铺认证）发送手机验证码
 * @return {*}
 */
export async function sendPhoneCode(payload) {
  try {
    const data = await axiosInstance.get(apis.sendPhoneCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 校验手机验证码
 * @return {*}
 */
export async function validatePhoneCode(payload) {
  try {
    const data = await axiosInstance.get(apis.validatePhoneCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询搜索热词列表
 * @return {*}
 */
export async function searchManagement(payload) {
  try {
    const data = await axiosInstance.get(apis.searchManagement, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 批量提交搜索热词
 * @return {*}
 */
export async function submitSearch(payload) {
  try {
    const data = await axiosInstance.post(apis.submitSearch, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 店铺列表
 * @return {*}
 */
export async function storePage(payload) {
  try {
    const data = await axiosInstance.post(apis.storePage, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询搜索管理下商品分组列表
 * @return {*}
 */
export async function searchProductGroupList(payload) {
  try {
    const data = await axiosInstance.get(apis.searchProductGroupList, payload)
    data.data = removeEmptyListNode(data.data)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询Banner列表
 * @return {*}
 */
export async function queryBannerList(payload) {
  try {
    const data = await axiosInstance.post(apis.queryBannerList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增/编辑Banner列表
 * @return {*}
 */
export async function insertOrUpdate(payload) {
  try {
    const data = await axiosInstance.post(apis.insertOrUpdate, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 删除指定Banner
 * @return {*}
 */
export async function deleteBanner(payload) {
  try {
    const data = await axiosInstance.get(apis.deleteBanner, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询Banner详情
 * @return {*}
 */
export async function queryBannerDetail(payload) {
  try {
    const data = await axiosInstance.get(apis.queryBannerDetail, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商品列表展示接口
 * @return {*}
 */
export async function getGoodsList(payload) {
  try {
    const data = await axiosInstance.post(apis.getGoodsList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商品列表展示接口
 * @return {*}
 */
export async function queryProductListNoPage(payload) {
  try {
    const data = await axiosInstance.post(apis.queryProductListNoPage, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增/编辑运营位
 * @return {*}
 */
export async function updateProductOperational(payload) {
  try {
    const data = await axiosInstance.post(apis.updateProductOperational, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询运营位分页列表
 * @return {*}
 */
export async function queryProductOperationalInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.queryProductOperationalInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询运营位详情
 * @return {*}
 */
export async function queryInfoById(payload) {
  try {
    const data = await axiosInstance.get(apis.queryInfoById, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改运营位状态
 * @return {*}
 */
export async function updateProductOperationalPublish(payload) {
  try {
    const data = await axiosInstance.post(apis.updateProductOperationalPublish, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 复制
 * @return {*}
 */
export async function operationCopy(payload) {
  try {
    const data = await axiosInstance.get(apis.operationCopy, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改Banner上下架状态
 * @return {*}
 */
export async function shelfBannerStatus(payload) {
  try {
    const data = await axiosInstance.get(apis.shelfBannerStatus, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 
 * @return {*}
 */
/**
 * @description: 批量添加品牌授权
 * @return {*}
 */
export async function batchUpdate(payload) {
  try {
    const data = await axiosInstance.post(apis.batchUpdate, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 批量添加类目扣点-资质
 * @return {*}
 */
export async function batchUpdateCategory(payload) {
  try {
    const data = await axiosInstance.post(apis.batchUpdateCategory, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 批量设置类目售后时效
 * @return {*}
 */
export async function batchSetAfterSaleDays(payload) {
  try {
    const data = await axiosInstance.post(apis.batchSetAfterSaleDays, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 分页查询店铺资质列表
 * @return {*}
 */
export async function queryStoreAuthorizationPageInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.queryStoreAuthorizationPageInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查看店铺资质-品牌审核详情
 * @return {*}
 */
export async function queryAuditLog(payload) {
  try {
    const data = await axiosInstance.get(apis.queryAuditLog, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 批量操作通过-不通过
 * @return {*}
 */
export async function batchUpdateAuth(payload) {
  try {
    const data = await axiosInstance.post(apis.batchUpdateAuth, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 批量设置折扣信息
 * @return {*}
 */
export async function updateStoreInfoDiscount(payload) {
  try {
    const data = await axiosInstance.post(apis.updateStoreInfoDiscount, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


// 批量导出接口
/**
* @description: 商品
* @return {*}
*/
export async function productBatchDownload(payload) {
  try {
    const data = await axiosInstance.post(apis.productBatchDownload, payload,  {
      responseType: 'blob'  // 例如，为文件下载请求设置
    })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description: 属性
* @return {*}
*/
export async function attributeBatchDownload(payload) {
  try {
    const data = await axiosInstance.get(apis.attributeBatchDownload, {
      responseType: 'blob'  // 例如，为文件下载请求设置
    }, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description: 分組
* @return {*}
*/
export async function groupingBatchDownload(payload) {
  try {
    const data = await axiosInstance.get(apis.groupingBatchDownload, {
      responseType: 'blob'  // 例如，为文件下载请求设置
    }, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description: 类目
* @return {*}
*/
export async function categoryBatchDownload(payload) {
  try {
    const data = await axiosInstance.get(apis.categoryBatchDownload, {
      responseType: 'blob'  // 例如，为文件下载请求设置
    }, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 品牌
 * @return {*}
 */
export async function brandBatchDownload(payload) {
  try {
    const data = await axiosInstance.get(apis.brandBatchDownload, {
      responseType: 'blob'  // 例如，为文件下载请求设置
    }, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
* @description:   通用跳转链接->商品列表-按分组-> 查询对应商品数据
* @return {*}
*/
export async function searchThirdLevelGroupingProducts(payload) {
  try {
    const data = await axiosInstance.get(apis.searchThirdLevelGroupingProducts, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:  查询商家账户
* @return {*}
*/
export async function queryBusinessAccounts(payload) {
  try {
    const data = await axiosInstance.post(apis.queryBusinessAccounts, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:  上传文件
* @return {*}
*/
export async function uploadFile(payload){
  try{
    const data = await axiosInstance.post(apis.uploadFile, payload, {  CustomContentType: 'multipart/form-data' })
    return data
  }catch(e){
    console.log(e)
  }
}

// 商城管理   --->   商品组件

/**
* @description:  商品组件列表
* @return {*}
*/
export async function productModuleList(payload){
  try{
    const data = await axiosInstance.post(apis.productModuleList, payload)
    return data
  }catch(e){
    console.log(e)
  }
}

/**
* @description:  商品组件 新增
* @return {*}
*/
export async function addProEl(payload){
  try{
    const data = await axiosInstance.post(apis.addProEl, payload)
    return data
  }catch(e){
    console.log(e)
  }
}

/**
* @description:  商品组件 编辑
* @return {*}
*/
export async function editProEl(payload){
  try{
    const data = await axiosInstance.post(apis.editProEl, payload)
    return data
  }catch(e){
    console.log(e)
  }
}

/**
* @description:  商品组件 上下架
* @return {*}
*/
export async function changeProElStatus(payload){
  try{
    const data = await axiosInstance.get(apis.changeProElStatus, { params: payload })
    return data
  }catch(e){
    console.log(e)
  }
}

/**
* @description:   商品组件详情
* @return {*}
*/
export async function productModuleDetail(payload) {
  try {
    const data = await axiosInstance.get(apis.productModuleDetail, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   商品组件删除
* @return {*}
*/
export async function delProEl(payload) {
  try {
    const data = await axiosInstance.get(apis.delProEl, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   校验sku下架是否活动商品限制
* @return {*}
*/
export async function skuCanDisableCheck(payload) {
  try {
    const data = await axiosInstance.post(apis.skuCanDisableCheck, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   获取首页优惠券
* @return {*}
*/
export async function productHomePageCouponQueryInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.productHomePageCouponQueryInfo, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   新增-修改优惠券组件数据
* @return {*}
*/
export async function productHomePageCouponUpdateProductHomePageCoupon(payload) {
  try {
    const data = await axiosInstance.post(apis.productHomePageCouponUpdateProductHomePageCoupon, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   更新优惠券组件状态
* @return {*}
*/
export async function productHomePageCouponUpdateStatus(payload) {
  try {
    const data = await axiosInstance.post(apis.productHomePageCouponUpdateStatus, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   获取首页优惠券
* @return {*}
*/
export async function operateQuery(payload) {
  try {
    const data = await axiosInstance.get(apis.operateQuery, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:   新建或编辑秒杀组件
* @return {*}
*/
export async function flashComponentInsertOrUpdate(payload) {
  try {
    const data = await axiosInstance.post(apis.flashComponentInsertOrUpdate, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
